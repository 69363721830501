.index-category-col {
  .news-card {
    width: 100%;
  	&-aspect {
  		@include aspect(7,5.5);
  		@include tablet {
  		}
  		@include desktop {
  		}			
  	}
  }
  &.secondary-videos {
    .news-card {
      width: 100%;
    	&-aspect {
    		@include tablet {
      		@include aspect(7,5.2);
    		}
    	}
    }
  }
}
.index-category-inline-grid {
  .news-card {
	  &-headline {
	    @extend %headline6;
  	}
  }
}
.blog-archive {
  @extend %block-padding;
  &-grid {
    padding: 0 0 1rem;
    @include tablet {
      max-width: $desktop-medium;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
    }
    @include desktop {
      padding: 0 0 2rem;
    }
    .news-card {
      transition-delay: 0s;
      margin-bottom: .5rem;
      @include tablet {
        margin-bottom: 0;
        width: 33.33%;
        padding: 1rem !important;
      }
      &-text {
        @include tablet {
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 1rem;
          max-height: none !important;
        }
        @include desktop {
          padding-left: 1.25rem;
          padding-right: 1.25rem;
          padding-bottom: 1.25rem;
        }        
      }
    	&-headline {
    		@extend %headline5;
    		margin-bottom: 1rem;
    	}      
    	&-blurb {
    	  display: none;
    	}
    }    
  }  
}

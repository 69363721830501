.index {
  &-category {
    background: $white;
		&.green {
			background: $primary;
		}
		&.blue {
			background: $secondary;
		}	
		&.dark-green {
			background: darken($primary, 20%);
		}
		&.dark-blue {
			background: darken($secondary, 20%);
		}			
		&.dark-gray {
			background: $darkgray;
		}	
		&.light-gray {
			background: $lightestgray;
		}				
		&.gray {
			background: $gray;
		}		
		&.black {
			background: $black;
		}	
		&:first-of-type {
  	  .index-category-wrap {
  		  @include phone {
  		    padding-top: .25rem;
  		  }
		  }
		}
		&-wrap {
		  max-width: $desktop-medium;
		  margin: 0 auto;
		  @extend %block-padding-small;
		  @include tablet {
		    padding-left: 2rem;
		    padding-right: 2rem;
		  }		  
		  @include desktop {
		    padding-left: 0;
		    padding-right: 0;
		  }
		}
		&-title {
		  @extend %headline2;
		  margin-bottom: 1rem;
		  color: $gray;
    	@include tablet {
  		  margin-bottom: 1.5rem;
    	}	
    	@include tablet {
  		  margin-bottom: 2rem;
    	}	    	
		  &-link {
		    color: $white;
		    transition: $slow;
		    padding: 0 .5rem;
		    &:hover {
		      color: $gray;
		    }
        &:focus {
        	box-shadow: inset 0 0 0 2px $white;
        }		    
		  }
		  @extend %slide-up;
		  &.reveal {
		    @extend %slide-up-reveal;
		  }
		}
    &-grid {
    	@include tablet {
    		width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
    	}      
    }	
    &-inline-grid {
    	@include tablet {
    		width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        .news-card {
          padding-top: 0 !important;
          @include desktop {
            width: 33.33%;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
    	} 
    	.news-card:nth-child(2) {
    	  transition-delay: 0s !important;
    	}
    }	    
    &-col {
    	@include tablet {
    		width: 50%;
    		&:last-child {
    		  padding-left: .25rem;
    		}
    		&.featured-video {
    		  width: 65%;
    		}
    		&.secondary-videos {
    		  width: 35%;
    		  padding-left: 0;
    		}    		
    	} 
    	@include phone {
    	  &:first-of-type {
    	    margin-bottom: 1.25rem;
    	  }
    	}
    }
  	&-more {
  		font-family: $headline;
  		color: $black;
  		margin-top: .5rem;
  		text-transform: uppercase;
  		font-size: .8rem;
  		display: block;
  		text-align: left;
  		@extend %slide-up;	
  		transition-delay: .4s !important;
  		&.reveal {
  			@extend %slide-up-reveal;	
  		}
  		&-link {
  			color: $lightgray;
  			text-transform: uppercase;
  			display: table;
  			padding: .5rem;
  			transition: $slow;	
  			@include phone {
  				text-align: center;
  			}
  			&-arrow {
  				width: .35rem;
  				max-height: .5rem;
  				margin-left: .35rem;
  				fill: $lightgray;
  				transition: $slow;
  			}
  			&:hover {
  				color: $primary;
  				.leadership-more-link {
    				&-arrow {
    					fill: $primary;
    				}
  			  }
  			}
  			&:focus {
        	box-shadow: inset 0 0 0 2px $white;
  			}
  		}		
  	}	
    &.dark-text {
      .index-category {
    		&-title {
    		  &-link {
    		    color: $gray;
    		    &:hover {
    		      color: $primary;
    		    }
            &:focus {
            	box-shadow: inset 0 0 0 2px $green;
            }    		    
    		  }
    		} 
    		&-more {
    		  &-link {
    		    &:focus {
    		      color: $green;
            	box-shadow: inset 0 0 0 2px $green;
    		    }
    		  }
    		}
      }
    }  
    &.green {
    	.index-category-more {
    		&-link {
    			color: $lightgray;
    			&-arrow {
    				fill: $lightgray;
    			}
    			&:hover {
    				color: $gray;
    				.leadership-more-link {
      				&-arrow {
      					fill: $gray;
      				}
    			  }
    			}
    		}	
    	}
    }
    &.dark-gray {
      .index-category {
    		&-title {
    		  &-link {
    		    &:hover {
    		      color: $primary;
    		    }
    		  }
    		}    
      }
    }      
  }
}

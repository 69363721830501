$max-width:          81.25em; //1300px
$small-phone:        20.5em;  //325px
$tablet:             48em;    //768px
$desktop-down:       64.1em;    //1024px
$desktop:            64em;    //1024px
$desktop-medium:     81.25em; //1300px
$desktop-large:      100em;   //1600px
$desktop-xl:         112.5em; //1800px
$max-width:          93.75em; //1500px
$max-width-text:     64em; //1500px

@mixin small-phone {
  @media (max-width: #{$small-phone}) {
    @content;
  }
}
@mixin phone {
  @media (max-width: #{$tablet}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop-down {
  @media (min-width: #{$desktop-down}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$desktop-medium}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$desktop-large}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$desktop-xl}) {
    @content;
  }
}
@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
@mixin edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}
	
/* Colors */
$green: #05ad52;
$darkgreen: #006633;
$blue: #1d548c;
$darkblue: #0b2138;
$lightblue: #2e81d4;
$yellow : #F3BD48;

$lightgray: #d0d0d0;
$lightestgray: #f4f4f4;
$graybg: #f1f1f1;
$gray: #505050;
$darkgray: #404141;

$black: #000;
$white: #fff;
$red: #d60000;

$primary: $green;
$secondary: $blue;
$text: $black;

$headline: 'Montserrat', sans-serif;
$body: 'Roboto Condensed', sans-serif;

$basic: all .2s ease-in-out;
$bounce: all .6s cubic-bezier(.5,1.65,.4,.8);
$slow: all .5s cubic-bezier(.57,.2,.21,.89);
$slow-curve: cubic-bezier(.57,.2,.21,.89);
$veryslow: all 1s cubic-bezier(.57,.2,.21,.89);

$shadow: 0 .5rem 1.25rem 0 rgba(0,0,0,.2);
$shadow-hover: 0 .5rem 1.5rem 0 rgba(0,0,0,.3);

$button-shadow: 0 .5rem 1rem 0 rgba(0,0,0,.2);
$button-shadow-hover: 0 .5rem 1.5rem 0 rgba(0,0,0,.3);
$button-shadow-active: inset 0 .5rem 1rem 0 rgba(0,0,0,.3);

%block-padding {
	padding: 1rem 1.5rem;
	@include tablet { 
		padding: 2rem 2rem;		
	}
	@include desktop {
		padding: 4rem 2rem;		
	}
	@include md { 
		padding: 5rem 2rem;				
	}	
	@include lg { 
		padding: 6rem 2rem;						
	}
	@include xl { 
		padding: 7rem 2rem;								
	}		
}
%block-padding-small {
	padding: 1.5rem;
	@include tablet { 
		padding: 1.5rem 1.5rem;		
	}
	@include desktop {
		padding: 2rem 2rem;		
	}
	@include md { 
		padding: 3rem 2rem;				
	}	
	@include lg { 
		padding: 4rem 2rem;						
	}
	@include xl { 
		padding: 4.5rem 2rem;								
	}		
}
%block-padding-top {
	padding-top: 1.5rem;
	@include tablet { 
		padding-top: 2rem;		
	}
	@include desktop {
		padding-top: 4rem;		
	}
	@include md { 
		padding-top: 5rem;				
	}	
	@include lg { 
		padding-top: 6rem;						
	}
	@include xl { 
		padding-top: 7rem;								
	}		
}
%block-padding-bottom {
	padding-bottom: 1.5rem;
	@include tablet { 
		padding-bottom: 2rem;		
	}
	@include desktop {
		padding-bottom: 4rem;		
	}
	@include md { 
		padding-bottom: 5rem;				
	}	
	@include lg { 
		padding-bottom: 6rem;						
	}
	@include xl { 
		padding-bottom: 7rem;								
	}		
}
%block-padding-top-small {
	padding-top: 1.5rem;
	@include tablet { 
		padding-top: 1.5rem;		
	}
	@include desktop {
		padding-top: 2rem;		
	}
	@include md { 
		padding-top: 3rem;				
	}	
	@include lg { 
		padding-top: 4rem;						
	}
	@include xl { 
		padding-top: 4.5rem;								
	}		
}
%block-padding-bottom-small {
	padding-bottom: 1.5rem;
	@include tablet { 
		padding-bottom: 1.5rem;		
	}
	@include desktop {
		padding-bottom: 2rem;		
	}
	@include md { 
		padding-bottom: 3rem;				
	}	
	@include lg { 
		padding-bottom: 4rem;						
	}
	@include xl { 
		padding-bottom: 4.5rem;								
	}		
}
%block-margin-top {
	margin-top: 1.5rem;
	@include tablet { 
		margin-top: 2rem;		
	}
	@include desktop {
		margin-top: 4rem;		
	}
	@include md { 
		margin-top: 5rem;				
	}	
	@include lg { 
		margin-top: 6rem;						
	}
	@include xl { 
		margin-top: 7rem;								
	}		
}
%block-margin-bottom {
	margin-bottom: 1.5rem;
	@include tablet { 
		margin-bottom: 2rem;		
	}
	@include desktop {
		margin-bottom: 4rem;		
	}
	@include md { 
		margin-bottom: 5rem;				
	}	
	@include lg { 
		margin-bottom: 6rem;						
	}
	@include xl { 
		margin-bottom: 7rem;								
	}		
}
%block-margin-top-small {
	margin-top: 1.5rem;
	@include tablet { 
		margin-top: 1.5rem;		
	}
	@include desktop {
		margin-top: 2rem;		
	}
	@include md { 
		margin-top: 3rem;				
	}	
	@include lg { 
		margin-top: 4rem;						
	}
	@include xl { 
		margin-top: 4.5rem;								
	}		
}
%block-margin-bottom-small {
	margin-bottom: 1.5rem;
	@include tablet { 
		margin-bottom: 1.5rem;		
	}
	@include desktop {
		margin-bottom: 2rem;		
	}
	@include md { 
		margin-bottom: 3rem;				
	}	
	@include lg { 
		margin-bottom: 4rem;						
	}
	@include xl { 
		margin-bottom: 4.5rem;								
	}		
}
%basic-text {
	line-height: 1.4;
	font-size: 1.05rem;	
	text-align: left;
  p {
    margin-bottom: 1rem;
    &last-of-type {
      margin-bottom: 0;
    }
  }	
	@include tablet {
		font-size: 1.2em;	
    p {
			margin-bottom: 1.1rem;	
    }			
	}
	@include desktop {
		font-size: 1.3rem;	
    p {
			margin-bottom: 1.2rem;	
    }				
	}	
	@include md {
    p {
			margin-bottom: 1.3rem;	
    }				
	}		
	@include lg {
    p {
			margin-bottom: 1.4rem;	
    }				
	}			
	@include xl {
    p {
			margin-bottom: 1.5rem;	
    }				
	}		
}
%headline1 {
  font-family: $headline;
	font-size: 2rem;
	@include tablet {
		font-size: 2.2rem;
	}		
	@include desktop {
		font-size: 2.5rem;
	}		
	@include md {
		font-size: 2.8rem;
	}	
	@include lg {
		font-size: 3.2rem;
	}	
	@include xl {
		font-size: 3.5rem;
	}				
}
%headline2 {
  font-family: $headline;
	font-size: 1.5rem;
	line-height: 1.1;
	@include tablet {
		font-size: 1.8rem;
	}		
	@include desktop {
		font-size: 2.1rem;
	}		
	@include md {
		font-size: 2.4rem;
	}	
	@include lg {
		font-size: 2.7rem;
	}	
	@include xl {
		font-size: 3rem;
	}				
}
%headline3 {
  font-family: $headline;
	font-size: 1.2rem;
	@include tablet {
		font-size: 1.3rem;
	}		
	@include desktop {
		font-size: 1.6rem;
	}		
	@include md {
		font-size: 1.9rem;
	}	
	@include lg {
		font-size: 2.3rem;
	}	
	@include xl {
		font-size: 2.6rem;
	}				
}
%headline4 {    
  font-family: $headline;
	font-size: 1.1rem;
	@include tablet {
		font-size: 1.2rem;
	}		
	@include desktop {
		font-size: 1.3rem;
	}		
	@include md {
		font-size: 1.5rem;
	}	
	@include lg {
		font-size: 1.8rem;
	}
	@include xl {
		font-size: 2rem;
	}				
}
%headline5 {
  font-family: $headline;
	font-size: 1rem;
	@include tablet {
		font-size: 1.1rem;
	}		
	@include desktop {
		font-size: 1.2rem;
	}		
	@include md {
		font-size: 1.3rem;
	}	
	@include lg {
		font-size: 1.4rem;
	}	
	@include xl {
		font-size: 1.5rem;
	}				
}
%headline6 {
  font-family: $headline;
	font-size: 1rem;
	@include tablet {
		font-size: 1.1rem;
	}		
	@include desktop {
		font-size: 1.15rem;
	}		
	@include md {
		font-size: 1.2rem;
	}	
	@include lg {
		font-size: 1.25rem;
	}	
	@include xl {
		font-size: 1.3rem;
	}				
}
%headline7 {
  font-family: $headline;
	font-size: .9rem;
	@include tablet {
		font-size: 1rem;
	}		
	@include desktop {
		font-size: .95rem;
	}		
	@include md {
		font-size: 1rem;
	}	
	@include lg {
		font-size: 1.1rem;
	}	
	@include xl {
		font-size: 1.15rem;
	}				
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}
@mixin aspect($x,$y) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
	padding-top: $padding;
}
@mixin placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}
@mixin screen-reader-text {  
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	word-wrap: normal !important;
	&:focus {
		background: $secondary;
		color: $white;
		clip: auto !important;
		display: inline-block;
		font-size: 1rem;
		height: auto;
		position: absolute;
		left: 0;
		top: 0;	
		z-index: 100000;		
		line-height: normal;
		padding: .5rem;
		width: auto;
	}
}
%clearfix { 
  &::before,
  &::after {
    display: table;
    content: '';
  }
  &::after {
    clear: both;
  }
}
%slide-up {
	transition: $veryslow;	
	opacity: 0;
	transform: translateY(3rem);
}
%slide-up-reveal {
	transition: $bounce;	
	opacity: 1;
	transform: translateY(0);
}
@import "../global/positioning"; 

.latest {
	position: relative;
	z-index: 100;
	margin-top: 52vh;
	@include tablet {
		margin-top: 50vh;		
	}
	@include desktop {
		margin-top: 60vh;		
	}		
	&-grid {
		max-width: $max-width;
		width: 100%;
		margin: 0 auto;
		@include tablet {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
			align-items: stretch;			
		}
		@include desktop {
		}			
	}
	&-left {
		@include tablet {
			padding: .5rem;
			margin-top: -1rem;			
			width: 67%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
		}
		@include desktop {
			padding: 1rem;
		}		
	}
	&-feed {
		width: 100%;
		padding: 0 .5rem;
		@include tablet {
			padding: 0;			
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
		}
		@include desktop {
		}		
		@include md {
			padding: 1rem;			
		}				
	}		
	&-right {
		text-align: left;
		padding: .5rem 1rem 1rem;
		@include tablet {
			width: 33%;
			padding: 0 1rem 0 0;			
		}
		@include md {
			padding: 1rem;
		}	
		.featured-action {
		  margin-bottom: 0;
		  @include tablet {
		    margin-bottom: 1rem;
		  }
		}
	}
}

.hero {
	width: 100%;
	position: relative;
	padding: 1rem 1rem 2rem;
	@include tablet {
		padding: 1.5rem 1.5rem 3rem;
	}		
	@include desktop {
		padding: 0 2rem 3rem;
	}		
	@include md {
		padding: 0 4rem 3rem;
	}		
	@include xl {
		padding: 0 6rem 3rem;
	}			
	&-headline {
		color: $white;
		font-family: $headline;
		margin-bottom: .5rem;
		@extend %headline1;
		line-height: 1.1;
		&.hide-headline {
		 @include screen-reader-text;
		}
		&.alt-font {
		 font-family: futura-pt-condensed, sans-serif;
		 letter-spacing: -.1rem;
		 text-transform: uppercase;
		 font-size: 4.5rem;
		 font-weight: 900;
		 @include tablet {
		  font-size: 7rem;
		 }
		 @include desktop {
		  font-size: 8.5rem;
		 }
		 @include md {
		  font-size: 9.5rem;
		 }
		 @include lg {
		  font-size: 10.5rem;
		 }
		 strong {
		  color: #39af2a;
    font-weight: 900;
		 }
		}
	}
	&-blurb {
		color: $white;
		line-height: 1.3;
		@extend %basic-text;
		text-align: center;
	}	
	&-photo {
		@include size(100%, 90vh);	
		@include absolute(left 0 top 0);
		z-index: 1;
		&-pic {
			@include size(100%);	
			@include absolute(left 0 top 0);
			object-fit: cover;
			overflow: hidden;	
		}
		&-img {
			@include size(100%);	
			object-fit: cover;
		}			
		&-fade {
			@include size(100%, 15vh);	
			@include absolute(left 0 bottom 0);			
			z-index: 50;
			display: block;
			background: linear-gradient(0deg, rgba($white,1) 0%, rgba($white,0) 100%);
		}
		&-text {
			@include size(100%, 50vh);	
			@include absolute(left 0 bottom 0);			
			z-index: 10;
			display: block;
			background: linear-gradient(0deg, rgba($black,1) 60%, rgba($black,0) 100%);
			&.green {
  			background: linear-gradient(0deg, rgba($green,1) 60%, rgba($green,0) 100%);
			}
			&.dark-green {
  			background: linear-gradient(0deg, rgba($darkgreen,1) 60%, rgba($darkgreen,0) 100%);
			}			
			&.dark-blue {
  			background: linear-gradient(0deg, rgba($darkblue,1) 60%, rgba($darkblue,0) 100%);
			}						
			&.blue {
  			background: linear-gradient(0deg, rgba($blue,1) 60%, rgba($blue,0) 100%);
			}			
			&.white {
  			background: linear-gradient(0deg, rgba($white,1) 60%, rgba($white,0) 100%);
			}						
		}		
		&-description {
		  @include screen-reader-text;
		}
	}
	.button {
		vertical-align: middle;
		&-block {
			margin-top: 1rem;
			@include tablet {
				margin-top: .75rem;
			}		
			@include desktop {
				margin-top: 1rem;
			}			
		}
  	&:focus {
    	box-shadow: 0 0 0 4px $green;
    	color: $green;
		}
		&-text {
			color: darken($primary, 15%) !important;
		}
		&:last-child {
			.button-text {
				color: $secondary !important;
			}	
		}
		&:before,
		&:after {
			display: none;
		}			
		&-or {
			text-transform: uppercase;
			color: $white;
			vertical-align: middle;
		}		
	}
	&.dark-text {
	  .hero {
    	&-headline {
    		color: $darkgreen;
    	}
    	&-blurb {
    		color: $black;
    	}	
	  }
	}
	&-union-difference {
    margin-top: -2rem;
    @include tablet {
      margin-top: 0;
    }	 
    @include desktop {
      margin-top: -5rem;
    }
    @include md {
      margin-top: -7rem;
    }    
    @include lg {
      margin-top: -9rem;
    }    
	  .hero {
    	&-headline {
    	 text-transform: uppercase;
	   	 text-shadow: 0 0 1rem rgba($black, .7);
   		 font-weight: 900;
		    font-size: 2.5rem;
		    line-height: .9;
   		 @include tablet {
   		  font-size: 3rem;
   		 }
   		 @include desktop {
   		  font-size: 4rem;
   		 }
   		 @include md {
   		  font-size: 5rem;
   		 }
   		 @include lg {
   		  font-size: 6rem;
   		 }	   	 
    	}
    	&-blurb {
 	   	 text-shadow: 0 0 1rem rgba($black, .7);
    	}	
	  }
	}
}


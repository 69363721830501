.blog-list {
  text-align: left;
	&-item {
		@extend %slide-up;	
		text-align: left;
		&.reveal {
			@extend %slide-up-reveal;	
		}
	}
	&-link {
    color: $white;
    display: block;
		padding: .8rem;
    @include tablet {
		  padding: 1rem .5rem;
    }
    @include lg {
		  padding: 1rem .5rem;
    }     
    &:hover {
      .blog-list {
        &-headline {
          color: $primary;
        }
      }
    }
    &:focus {
    	box-shadow: inset 0 0 0 2px $green;
    }
  }
	&-date {
		color: $gray;
		font-size: 85%;
		text-transform: uppercase;
	}  
	&-headline {
		@extend %headline5;	
		margin-bottom: .5rem;
		transition: $slow;
	}
	&-description {
		@extend %basic-text;
		line-height: 1.1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;		
	}	
}
.index-category.dark-text {
  .blog-list {
  	&-link {
      &:hover {
        .blog-list {
          &-headline {
            color: $primary;
          }
        }
      }
    }    
  	&-headline {
  		color: $gray;
  	}
  	&-description {
  		color: $gray;
  	}	
  }
}	
.index-category.green {
  .blog-list {
  	&-link {
      &:hover {
        .blog-list {
          &-headline {
            color: $gray;
          }
        }
      }
      &:focus {
      	box-shadow: inset 0 0 0 2px $white;
      }      
    }    
  }
}	

